import React from "react";
import { SectionTitle, Container, BenefitCard } from "components";

function WhyInflectiv() {
  return (
    <section className="relative py-[50px] lg:py-[100px] bg-black overflow-hidden">
      <Container className="relative max-w-developer z-10">
        <div className="grid sm:grid-cols-2 laptop:grid-cols-3 gap-4 md:gap-5 items-center">
          <div className="sm:col-span-2">
            <SectionTitle className="text-center laptop:text-left laptop:max-w-[85%] xl:max-w-[75%] mb-10 lg:mb-0">
              Why Choose Inflectiv Over Centralized Platforms?
            </SectionTitle>
          </div>

          <div className="col-span-1">
            <BenefitCard
              icon={`${process.env.REACT_APP_S3_ASSETS_URL}benefit-1.webp`}
              title="Community-Driven Ecosystem"
              desc="Collaborate with a growing community of developers and gain from decentralized governance, giving you more control over your development environment."
            />
          </div>
          <div className="col-span-1">
            <BenefitCard
              icon={`${process.env.REACT_APP_S3_ASSETS_URL}benefit-2.webp`}
              title="Superior Training Data"
              desc="Inflectiv provides access to diverse, high-quality datasets that aren’t siloed by centralized gatekeepers. Use this to train your AI models and ensure superior output."
            />
          </div>
          <div className="col-span-1">
            <BenefitCard
              icon={`${process.env.REACT_APP_S3_ASSETS_URL}benefit-3.webp`}
              title="Scalable, Secure, and Flexible"
              desc="Whether you're starting small or scaling up, Inflectiv’s decentralized infrastructure is designed to handle your app’s growth, with flexible and secure data management. "
            />
          </div>
          <div className="col-span-1">
            <BenefitCard
              icon={`${process.env.REACT_APP_S3_ASSETS_URL}benefit-4.webp`}
              title="Custom APIs for AI Integration"
              desc="Create powerful APIs for a variety of LLM that leverage your custom datasets, giving your AI applications a competitive edge with better data-driven outputs."
            />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default WhyInflectiv;
