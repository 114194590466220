import React from "react";
import { Container, SectionDetail, SectionTitle } from "components";
import { INFECTIVlOGOS } from "utils/constants";

function DecentratizedFuture() {
  return (
    <section className="relative bg-lotion py-[90px] laptop:py-[180px] overflow-hidden">
      <Container className="max-w-inner z-20">
        <div className="grid laptop:grid-cols-2 gap-12 md:gap-20 laptop:gap-8 items-center">
          <div className="col-span-1">
            <div className="max-w-[90%] lg:max-w-[75%] laptop:max-w-[600px] mx-auto laptop:mx-0 text-center laptop:text-left">
              <SectionTitle className="text-lightGray mb-5 lg:mb-8">
                Decentralized Future of AI
              </SectionTitle>
              <SectionDetail className="text-lightGray/85">
                {/* Inflectiv is the engine which powers the decentralised future of
                AI. It provides a powerful next generation AI secured
                infrastructure and services platform. It is what the AI apps of
                the future will run on. */}
                Inflectiv is the engine which powers the decentralized future of
                AI. It provides a powerful next generation AI secured
                infrastructure, services and training data marketplace.{" "}
                <span className="font-bold">
                  It will power the AI apps of the future.
                </span>
                {/* Inflectiv is the engine which powers the decentralized future of AI. It provides a powerful next generation AI secured infrastructure, services and training data marketing platform. <span className="font-bold">It will power the AI apps of the future.</span> */}
              </SectionDetail>

              {/* <div className="mt-6 lg:mt-9">
                <Button
                  className="dark-button relative px-4 py-3 md:px-6 md:py-[14px]"
                  onClick={() => navigate("/")}
                >
                  Know More
                  <span
                    className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                  >
                    {ARROW_ICONS.circleArrow}
                  </span>
                </Button>
              </div> */}
            </div>
          </div>

          <div className="col-span-1 flex items-center justify-center">
            <div className="relative w-[280px] h-[280px] md:w-[320px] md:h-[320px] lg:w-[624px] lg:h-[624px] flex items-center justify-center">
              <div className="absolute top left-0 w-full h-full flex items-center justify-center border-[2px] border-orange rounded-full rotate">
                {/* {[network1, network3, network4, network6, network2].map((icon, index) => {
                  const { left, top } = generateRandomPosition();
                  return (
                    <SingleCircleItem
                      key={index}
                      left={left}
                      top={top}
                      icon={icon}
                      className="bg-black opacity-100 w-[40px] h-[40px] laptop:w-[80px] laptop:h-[80px]"
                    />
                  );
                })} */}
                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[20%] left-[10%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}1.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[80%] left-[10%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}2.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[20%] left-[90%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}3.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[80%] left-[90%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}4.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] -top-[8%] lg:-top-[7%] left-[50%]`}
                  style={{
                    transform: `translateX(-50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}5.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              </div>

              <div className="absolute w-[65%] h-[65%] flex items-center justify-center border-[2px] border-orange rounded-full antirotation">
                {/* {[network2, network5, network1].map((icon, index) => {
                    const { left, top } = generateRandomPosition();
                    return (
                      <SingleCircleItem
                        key={index}
                        left={left}
                        top={top}
                        icon={icon}
                        className="bg-black opacity-100 w-[40px] h-[40px] laptop:w-[80px] laptop:h-[80px]"
                      />
                    );
                  })} */}

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[50%] left-0`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}6.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] top-[50%] left-[100%]`}
                  style={{
                    transform: `translate(-50%, -50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}7.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
                <div
                  className={`circle absolute rounded-full bg-black/10 z-40 p-[2px] laptop:p-[6px] bg-black w-[40px] h-[40px] md:w-[50px] md:h-[50px]  lg:w-[60px] laptop:w-[80px] lg:h-[60px] laptop:h-[80px] -top-[10%] left-[50%]`}
                  style={{
                    transform: `translateX(-50%)`,
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_S3_ASSETS_URL}8.png`}
                    alt=""
                    className="w-full h-full"
                  />
                </div>
              </div>

              <div className="absolute max-w-[30%] lg:max-w-[60%] *:w-full *:h-full flex items-center justify-center *:relative *:top-2">
                {INFECTIVlOGOS.inflectivShadow}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default DecentratizedFuture;
