import React from "react";
import { motion } from "framer-motion"

export default function Button({
  children,
  type = "button",
  className = "",
  ...props
}) {
  return (
    <motion.button
      type={type}
      className={`inline-flex justify-center items-center bg-transparent text-xsm lg:text-sm/6 font-medium rounded-full outline-0 -tracking-[0.42] duration-300 cursor-pointer ${className}`}
      {...props}
    >
      {children}
    </motion.button>
  );
}