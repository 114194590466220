import React from "react";
import Banner from "./sections/Banner";
import DecentratizedFuture from "./sections/DecentratizedFuture";
import AiApp from "./sections/AiApp";
// import PoweredByIAI from "./sections/PoweredByIAI";
import NetworkNodes from "./sections/NetworkNodes";
import InflectivForDevelopers from "./sections/InflectivForDevelopers";
import InflectivNetwork from "../common-section/InflectivNetwork";
import Community from "../common-section/Community";
import NextGenApps from "./sections/NextGenApps";

function Homepage() {
    return (
        <>
            <Banner />
            <DecentratizedFuture />
            <InflectivNetwork darkMode />
            <NextGenApps />
            <AiApp />
            <Community />
            <NetworkNodes />
            {/* <PoweredByIAI /> */}
            <InflectivForDevelopers />
        </>
    );
}

export default Homepage;
