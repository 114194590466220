import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export default function DashboardScreen() {
  return (
    <Swiper
      slidesPerView={1}
      autoplay={{ delay: 3000 }}
      pagination={{ clickable: true }}
      effect={"fade"}
      spaceBetween={20}
      loop={true}
      modules={[Autoplay, EffectFade, Pagination]}
      className="db-slider max-w-[1200px] mx-auto !pb-10 lg:!pb-14"
    >
      <SwiperSlide className="flex items-center justify-center">
        <img src={`${process.env.REACT_APP_S3_ASSETS_URL}dashboard-s1.webp`} alt="dashboard screen" className='object-cover w-full h-full rounded-xl lg:rounded-[24px]' />
      </SwiperSlide>
      <SwiperSlide className="flex items-center justify-center">
        <img src={`${process.env.REACT_APP_S3_ASSETS_URL}dashboard-s2.webp`} alt="dashboard screen" className='object-cover w-full h-full rounded-xl lg:rounded-[24px]' />
      </SwiperSlide>
      <SwiperSlide className="flex items-center justify-center">
        <img src={`${process.env.REACT_APP_S3_ASSETS_URL}dashboard-s3.webp`} alt="dashboard screen" className='object-cover w-full h-full rounded-xl lg:rounded-[24px]' />
      </SwiperSlide>
    </Swiper>
  )
}
