import React, { useState, useEffect } from 'react';
import {
  Container,
  SectionDetail,
  SectionTitle,
  Button,
  DashboardScreen,
  SignupModal
} from "components";
import { ARROW_ICONS } from "utils/constants";

export default function Community() {
  const [isShowWhiteListModal, setShowWhiteListModal] = useState(false);

  useEffect(() => {
    const bodyElement = document.body;
    if (isShowWhiteListModal) {
      bodyElement.classList.add("overflow-hidden");
    } else {
      bodyElement.classList.remove("overflow-hidden");
    }
  }, [isShowWhiteListModal]); // This hook runs when isShowWhiteListModal changes
  
  return (
    <>
      <section className="relative py-[90px] laptop:py-[180px] bg-white overflow-hidden">
        <Container className="max-w-inner">
          <div className="text-center max-w-[90%] lg:max-w-[75%] laptop:max-w-[1080px] mx-auto">
            <SectionTitle className="mb-5 lg:mb-8 !text-black">
              Decentralized, Governed and Run by the Inflectiv Community
            </SectionTitle>
            <SectionDetail className="!text-black">
              The Node management dashboard is there for the community for them to
              be able to manage their nodes, see how the network is operating and
              reap the benefits of being part of the inflective universe.
            </SectionDetail>

            <div className="mt-6 lg:mt-9">
              <Button
                className="dark-button relative px-4 py-[11px] lg:px-6 lg:py-[14px]"
                onClick={() => setShowWhiteListModal(true)}
              >
                Sign up for Whitelist
                <span
                  className={`ml-2 w-5 h-5 md:w-[26px] md:h-[26px] flex items-center justify-center *:w-full *:h-full duration-300`}
                >
                  {ARROW_ICONS.circleArrow}
                </span>
              </Button>
            </div>
          </div>
        </Container>

        <Container className="!max-w-[1680px] mt-[50px] lg:mt-[100px]">
          <DashboardScreen />
        </Container>
      </section>
      {isShowWhiteListModal && (
        <SignupModal
          isToggled={isShowWhiteListModal}
          setToggled={() => setShowWhiteListModal(!isShowWhiteListModal)}
        />
      )}
    </>
  );
}
