import React from "react";
import { Container, SectionDetail, SectionTitle } from "components";

function NextGenApps() {
  return (
    <section className="relative py-[50px] lg:py-[90px] bg-black overflow-hidden gradient-cirlce before:top-9 before:-right-1/2 lg:before:right-0">
      <Container className="max-w-inner">
        <div className="text-center max-w-[90%] lg:max-w-[75%] laptop:max-w-[940px] mx-auto">
          <SectionTitle className="mb-5 lg:mb-8">
            Next Generation <br /> of AI Decentralized Apps
          </SectionTitle>
          <SectionDetail>
            {/* Allowing all AI apps to be run on a decentralised, secure network, powered by the users, with multiple AAA AI applications launching within the first year. */}
            Enabling the AI applications of the future.
          </SectionDetail>
        </div>

        <div className="relative mt-[50px] lg:mt-[100px] z-10">
          <div className="w-full h-full bg-lightSilver/[.08] rounded-xl lg:rounded-[32px] flex items-center justify-center p-2 lg:p-4">
            <img
              src={`${process.env.REACT_APP_S3_ASSETS_URL}app-image.webp`}
              alt=""
              className="object-cover max-w-full max-h-full rounded-xl lg:rounded-[32px]"
            />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default NextGenApps;
