import { React, useEffect } from "react";
import "./style.css";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="relative w-full h-full py-[150px] laptop:py-[200px] content-page bg-contentPageBg">
      <div className="relative max-w-[1140px] mx-auto px-6">
        <h1 className="font-medium tracking-tighter laptop:-tracking-[1px] text-white text-center">
          Privacy Policy
        </h1>
        <h2>1. Introduction</h2>
        <p><a href="http://inflectiv.ai/" className="underline text-orange hover:no-underline whitespace-nowrap" target="_blank" rel="noopener noreferrer">Inflectiv.ai</a>  ("we," "us," or "our") is committed to safeguarding your privacy. This Privacy Policy outlines our practices regarding the collection, use, disclosure, and protection of your personal information when you access our website, utilize our services, or engage with us in any other manner. By using our services, you consent to the collection and use of your information as described in this policy.
        </p>
        <h2>2. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li><strong>Personal Information: </strong> This includes any information you voluntarily provide to us, such as your name, email address, phone number, and any other details necessary for us to provide you with our services.</li>
          <span className="mt-16"></span>
          <li><strong>Usage Data: </strong> We automatically collect information on how you interact with our website and services. This data may include your IP address, browser type, access times, and the pages you have visited.</li>
          <span className="mt-16"></span>
          <li><strong>Cookies and Tracking Technologies: </strong> We employ cookies and similar technologies to track the activity on our website and store certain information. You can choose to accept or refuse these cookies through your browser settings.</li>
          <span className="mt-16"></span>
        </ul>
        <h2>3. How We Use Your Information</h2>
        <p>The information we collect is used for various purposes, including but not limited to:</p>
        <ul>
          <li>Providing, maintaining, and enhancing our services. </li>
          <span className="mt-16"></span>
          <li>Informing you about changes or updates to our services.          </li>
          <span className="mt-16"></span>
          <li>Enabling you to participate in interactive features of our services if you so choose.</li>
          <span className="mt-16"></span>
          <li>Offering customer support and addressing inquiries or issues.    </li>
          <span className="mt-16"></span>
          <li>Compiling analysis to improve our services and user experience.    </li>
          <span className="mt-16"></span>
          <li>Monitoring the usage of our services to detect, prevent, and address technical issues.    </li>
          <span className="mt-16"></span>
        </ul>
        <h2>4. Disclosure of Your Information</h2>
        <p>Your information may be shared in the following circumstances:        </p>
        <ul>
          <li><strong>With Service Providers: </strong> We may engage third-party vendors, contractors, or agents to perform services on our behalf, in which case they will have limited access to your information as needed to perform their functions and are obligated not to disclose or use it for other purposes.</li>
          <span className="mt-16"></span>
          <li><strong>For Business Transfers: </strong> In connection with, or during negotiations of, any merger, sale of assets, financing, or acquisition of all or part of our business by another entity.</li>
          <span className="mt-16"></span>
          <li><strong>With Affiliates: </strong> We may share your information with our affiliates, requiring them to adhere to this Privacy Policy.          </li>
          <span className="mt-16"></span>
        </ul>
        <h2>5. Security of Your Information</h2>
        <p>We prioritize the security of your personal information and employ commercially reasonable methods to protect it. However, please be aware that no method of transmission over the internet, or method of electronic storage, is entirely secure, and we cannot guarantee its absolute protection.    </p>
        <h2>6. Your Data Protection Rights</h2>
        <p>Depending on your jurisdiction, you may have the following data protection rights:</p>
        <ul>
          <li>The right to access, update, or delete the personal information we hold about you. </li>
          <span className="mt-16"></span>
          <li>The right to rectify incorrect or incomplete information.     </li>
          <span className="mt-16"></span>
          <li>The right to object to our processing of your information.</li>
          <span className="mt-16"></span>
          <li>The right to request the restriction of processing your data.          </li>
          <span className="mt-16"></span>
          <li>The right to data portability.   </li>
          <span className="mt-16"></span>
          <li> The right to withdraw consent for data processing activities reliant on consent.    </li>
          <span className="mt-16"></span>
        </ul>
        {/* <p>To exercise any of these rights, please contact us using the details provided below.</p> */}
        <h2>7. Changes to This Privacy Policy</h2>
        <p>We reserve the right to update or modify this Privacy Policy at any time. Changes will be effective immediately upon posting them on this page. We advise you to review this Privacy Policy periodically to stay informed of any changes.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
