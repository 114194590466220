import React from "react";

export default function NodeCard({ nodebg, nodeIcon, bgPattern, children }) {
  return (
    <div className="nodeCard relative bg-dim rounded-[32px] border-[1.5px] border-dim/[.08] min-h-[400px] laptop:min-h-[523px] h-full py-4 px-4 laptop:py-[32px] laptop:px-[30px] overflow-hidden">
      <div
        className={`absolute top-0 left-0 w-full h-full z-[2] before:absolute before:top-0 before:left-0 before:w-full before:h-full ${bgPattern} before:bg-no-repeat before:bg-center before:bg-contain`}
      >
        <img
          src={nodebg}
          alt="node bg"
          className="object-cover w-full h-auto relative z-10"
        />
      </div>

      <div className="relative flex flex-col justify-end h-full gap-5 z-10">
        {/* <div className="relative w-16 h-16 laptop:w-[78px] laptop:h-[78px] flex items-center justify-center">
          <img src={nodeIcon} alt="nodeIcon" className='absolute top-0 left-0' />
        </div> */}
        <h3 className="text-[20px] laptop:text-[24px] leading-7 font-normal text-black capitalize -tracking-[2.1px]">
          {children}
        </h3>
      </div>
    </div>
  );
}
