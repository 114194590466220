import React from 'react'
import Banner from './sections/Banner'
import ToolsData from './sections/ToolsData'
import WhyInflectiv from './sections/WhyInflectiv'
import ProductGrowth from './sections/ProductGrowth'
import StartDeveloping from './sections/StartDeveloping'

function Developers() {
  return (
    <>
      <Banner />
      <ToolsData />
      <WhyInflectiv />
      <ProductGrowth />
      <StartDeveloping />
    </>
  )
}

export default Developers