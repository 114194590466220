import React from "react";

function BenefitCard({ icon, title, desc }) {
  return (
    <div className="relative bg-chineseBlack rounded-[32px] border-[1.5px] border-white/[.06] min-h-[280px] lg:min-h-[300px] laptop:min-h-[340px] h-full p-5 laptop:p-[25px] flex overflow-hidden">
      <div className="relative flex flex-col">
        <div className="flex flex-col justify-between h-full gap-8">
          <div className="relative w-14 lg:w-20 h-14 lg:h-20 laptop:w-[90px] laptop:h-[90px] flex items-center justify-center">
            <img src={icon} alt={title} className="absolute top-0 left-0" />
          </div>
          <h3 className="text-base/5 laptop:text-[22px]/6 font-normal text-white capitalize -tracking-11">
            {title}
          </h3>
        </div>

        <div className="relative  mt-5 laptop:mt-[25px] min-h-[100px] lg:min-h-[96px]">
          <p className="text-white/75 text-xsm/5 lg:text-sm/6 font-normal -tracking-03">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BenefitCard;
