import React from "react";
import { SectionTitle, SectionDetail, Container } from "components";

function ToolsData() {
  return (
    <section className="relative bg-lotion py-[50px] laptop:py-[100px]">
      <Container className="max-w-developer z-20">
        <div className="grid laptop:grid-cols-2 gap-6 lg:gap-10 xl:gap-[160px] text-center laptop:text-left">
          <div className="col-span-1">
            <SectionTitle className="text-lightGray laptop:-tracking-[5.5px]">
              Access High-Quality AI Tools & AI Training Data for Your App
            </SectionTitle>
          </div>

          <div className="col-span-1">
            <SectionDetail className="text-lightGray/85 laptop:pt-4">
              As a developer, you know that the quality of your AI app depends
              on having access to the best training data and tools. Inflectiv
              offers you something that goes beyond centralized solutions: a
              decentralized network designed to give you state-of-the-art AI and
              machine learning resources, along with high-quality training
              datasets curated for optimal performance.
            </SectionDetail>
          </div>
        </div>

        <div className="relative mt-8 laptop:mt-[60px]">
          <div className="flex items-center gap-3 text-sm/6 font-medium -tracking-1 text-lightGray">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="14"
                viewBox="0 0 11 14"
                fill="none"
              >
                <path
                  d="M11 7L0.499999 13.0622L0.5 0.937822L11 7Z"
                  fill="#FF794C"
                />
              </svg>
            </span>
            On Inflectiv, you’ll have access to:
          </div>

          <div className="grid lg:grid-cols-2 gap-5 mt-8">
            <div className="col-span-1">
              <div className="relative bg-dim border-[1px] border-black/10 backdrop-blur-[3px] rounded-2xl laptop:rounded-[32px] max-h-[350px] laptop:max-h-[491px] flex flex-col justify-between h-full overflow-hidden">
                <div className="p-5 laptop:p-10 w-full">
                  <div className="max-w-[464px]">
                    <h3 className="text-xl/7 laptop:text-[26px]/[30px] font-bold -tracking-12 text-black mb-2 laptop:mb-[22px]">
                      Top-tier Training Data
                    </h3>
                    <p className="text-xsm/5 lg:text-sm/6 font-normal -tracking-03 text-black/75">
                      Access diverse, high-quality training datasets to fuel your AI models and drive better results.
                    </p>
                  </div>
                </div>

                <div className="relative bottom-0 right-0 pl-6 md:pl-10 laptop:pl-[90px] flex justify-end items-end">
                  <img src={`${process.env.REACT_APP_S3_ASSETS_URL}tool-1.png`} alt="" className="max-h-full" />
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="relative bg-dim border-[1px] border-black/10 backdrop-blur-[3px] rounded-2xl laptop:rounded-[32px] max-h-[350px] laptop:max-h-[491px] flex flex-col justify-between h-full overflow-hidden">
                <div className="p-5 laptop:p-10 w-full">
                  <div className="max-w-[464px]">
                    <h3 className="text-xl/7 laptop:text-[26px]/[30px] font-bold -tracking-12 text-black mb-2 laptop:mb-[22px]">
                      Custom Training Data Repositories
                    </h3>
                    <p className="text-xsm/5 lg:text-sm/6 font-normal -tracking-03 text-black/75">
                      Build your own data sets, and combine your data with purchased datasets from the marketplace to enhance your AI training outputs.
                    </p>
                  </div>
                </div>

                <div className="relative bottom-0 right-0 pl-6 md:pl-10 laptop:pl-[90px] flex justify-end items-end">
                  <img src={`${process.env.REACT_APP_S3_ASSETS_URL}tool-2.png`} alt="" className="max-h-full" />
                </div>
              </div>
            </div>

            <div className="lg:col-span-2">
              <div className="relative bg-dim border-[1px] border-black/10 backdrop-blur-[3px] rounded-2xl laptop:rounded-[32px] max-h-[350px] laptop:max-h-[491px] lg:max-h-[449px] flex justify-between lg:justify-start flex-col lg:flex-row lg:items-center h-full overflow-hidden">
                <div className="p-5 lg:pr-0 w-full max-w-[464px] laptop:p-10">
                  <div className="max-w-[464px]">
                    <h3 className="text-xl/7 laptop:text-[26px]/[30px] font-bold -tracking-12 text-black mb-2 laptop:mb-[22px]">
                      Custom AI model integrations
                    </h3>
                    <p className="text-xsm/5 lg:text-sm/6 font-normal -tracking-03 text-black/75">
                      Boost the performance of your AI apps by using Inflectiv’s API creation tool, which allows you to integrate your custom training data with AI integration in your code base for superior outputs.
                    </p>
                  </div>
                </div>

                <div className="relative bottom-0 right-0 pl-6 md:pl-10 laptop:pt-[61px] flex justify-end items-end">
                  <img src={`${process.env.REACT_APP_S3_ASSETS_URL}tool-3.png`} alt="" className="max-h-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ToolsData;
