import React from "react";
import { motion } from "framer-motion";

function SectionTag({ children, className = "", props }) {
    return (
        <div
            // className={`tag text-xs lg:text-xl lg:leading-6 font-medium tracking-03 px-4 py-2 lg:py-[16px] lg:px-5 rounded-full w-fit text-dim bg-label bg-chineseBlack/50 backdrop-blur-[15px] bg-no-repeat bg-[length:100%_100%] flex items-center justify-center gap-2 lg:gap-[10px] shadow-orange shadow-2xl ${className}`}
            className="text-center"
            {...props}
        >
            {children}
        </div>
    );
}
function SectionTitle({ children, className = "" }) {
    return (
        <h2
            style={{ fontSize: "clamp(1.875rem, 1.1852rem + 2.94314vw, 4.625rem)", lineHeight: "clamp(32px, 1.1852rem + 2.94314vw, 76px)" }}
            className={`font-bold -tracking-[1.5px] laptop:-tracking-[3.18px] ${className} text-dim`}
        >
            {children}
        </h2>
    );
}

function SectionDetail({ children, className = "", ...props }) {
    return (
        <motion.p
            // style={{ fontSize: "clamp(1.125rem, 1.07339rem + 0.258065vw, 1.375rem)", lineHeight: "clamp(24px, 1.07339rem + 0.258065vw, 32px)" }}
            style={{ fontSize: "clamp(18px, 2vw, 22px)", lineHeight: "clamp(24px, 2.2vw, 32px)" }}
            className={`font-normal text-dim/80 -tracking-08 ${className}`}
            {...props}
        >
            {children}
        </motion.p>
    );
}

export { SectionTitle, SectionDetail, SectionTag };
