import React, {useState} from "react";
import ReactPlayer from "react-player";

export default function VideoPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleReady = () => {
    setIsPlaying(true);
  };
  return (
    <div className="w-[200px] md:w-[230px] laptop:w-[350px] mx-auto h-full relative z-10 rounded-[16px] md:rounded-[28px] laptop:rounded-[45px] inflectivVideoHolder">
      <ReactPlayer
        className="react-player"
        url={`${process.env.REACT_APP_S3_ASSETS_URL}joinNow-vd.mp4`}
        // light={`${process.env.REACT_APP_S3_ASSETS_URL}joinNow-poster.webp`}
        width="100%"
        height="100%"
        playing={isPlaying}
        onReady={handleReady}
        muted={true}
        loop={true}
        playsinline={true}
      />
    </div>
  );
}
