import React, { useLayoutEffect } from "react";
import { Footer, Header } from "components";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function Layout({ children }) {
  const robotsMeta = process.env.REACT_APP_ROBOTS || "noindex,nofollow";

  const location = useLocation();

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <meta name="robots" content={robotsMeta} />
      </Helmet>

      <Header />
      <main
        className="relative w-full overflow-hidden"
        style={{ minHeight: "calc(100vh - 240px)" }}
      >
        {children}
      </main>
      <Footer />
    </>
  );
}

export default Layout;
