import React from "react";
import { SectionTitle, SectionDetail } from "components";
import { useMediaQuery } from "hooks/useMediaQuery";

function ProductGrowth() {
  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <section className="relative py-[50px] laptop:py-[100px] bg-white overflow-hidden">
      <div className="max-w-developer px-[10px] mx-auto sm:px-8">
        <div className="text-center max-w-[90%] lg:max-w-[75%] laptop:max-w-[966px] mx-auto">
          <SectionTitle className="mb-5 lg:mb-8 !text-black">
            {/* Accelerate Your AI App Growth & get Exposure */}
            Why Inflectiv Offers Superior AI Performance
          </SectionTitle>
          <SectionDetail className="!text-black max-w-[844px] mx-auto">
            {/* Inflectiv isn't just about building apps—it’s about helping you grow
            them. Publish your app on{" "}
            <span className="font-semibold">Inflectiv’s App Store</span> and tap
            into a global network of users ready to engage with your AI
            solutions. Whether you're developing for enterprise or consumer use
            cases, our platform offers growth accelerators that include: */}
            Here’s how <span className="font-bold">Inflectiv</span> compares to
            traditional centralized platforms:
          </SectionDetail>
        </div>

        <div className="mt-[50px] lg:mt-[50px] w-full">
          {/* <div className="border-[4px] border-white/[0.14] xl:w-full bg-black px-5 pt-2 pb-3 rounded-2xl relative overflow-x-auto">
          <table className="w-full leading-normal xl:table-fixed relative z-10">
            <thead className="">
              <tr className="">
                <th scope="col" className="text-white py-4 px-3 bg-gray-100 text-left font-semibold text-sm capitalize whitespace-nowrap tracking-[0px]">
                  Feature
                </th>
                <th scope="col" className="text-white py-4 px-3 bg-gray-100 text-left font-semibold text-sm capitalize whitespace-nowrap tracking-[0px]">
                  Standard Gemini Integration
                </th>
                <th scope="col" className="text-white py-4 px-3 bg-gray-100 text-left font-semibold text-sm capitalize whitespace-nowrap tracking-[0px]">
                  Inflectiv AI Gemini Integration
                </th>
              </tr>
            </thead>
            <tbody>
            <tr className=" ">
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm whitespace-nowrap bg-coral/[0.85] rounded-l-xl mb-1">
                  Data Access
                  </div>
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.85]  whitespace-nowrap mb-1">
                    Limited centralized datasets
                  </div>
                
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.85] rounded-r-xl whitespace-nowrap mb-1">
                  Decentralized, curated high-quality datasets
                  </div>
                </td>
              </tr>
              <tr className=" ">
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm   whitespace-nowrap bg-coral/[0.15] rounded-l-xl mb-1">
                  AI Output Quality
                  </div>
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.15]  whitespace-nowrap mb-1">
                  Standard LLM performance
                  </div>
                
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.15] rounded-r-xl whitespace-nowrap mb-1">
                  10x improved output with superior training data
                  </div>
                </td>
              </tr>
              <tr className=" ">
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm   whitespace-nowrap bg-coral/[0.85] rounded-l-xl mb-1">
                  Custom API Creation
                  </div>
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.85] whitespace-nowrap mb-1">
                  Basic API setup
                  </div>
                
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.85] rounded-r-xl whitespace-nowrap mb-1">
                  Advanced custom API creation using high-quality training data
                  </div>
                </td>
              </tr>
              <tr className=" ">
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm whitespace-nowrap bg-coral/[0.15] rounded-l-xl mb-1">
                  Scalability
                  </div>
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.15]  whitespace-nowrap mb-1">
                  Limited to centralized servers
                  </div>
                
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.15] rounded-r-xl whitespace-nowrap mb-1">
                  Scalable, decentralized infrastructure
                  </div>
                </td>
              </tr>
              <tr className=" ">
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm   whitespace-nowrap bg-coral/[0.85] rounded-l-xl mb-1">
                  Cost Efficiency
                  </div>
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.85]  whitespace-nowrap mb-1">
                  High cost for premium features
                  </div>
                
                </td>
                <td className="">
                  <div className="py-4 px-3 text-gray-900 text-sm bg-coral/[0.85] rounded-r-xl whitespace-nowrap mb-1">
                  Lower costs with flexible, pay-per-query access
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}

          <img
            src={`${process.env.REACT_APP_S3_ASSETS_URL}${isMobile ? "mob-comparison-table.svg" : "comparison-table.svg"}`}
            alt="comparison table"
            className="mx-auto"
          />
        </div>
        <div className="mt-[50px] lg:mt-[50px] text-center">
          <SectionDetail className="!text-black max-w-[1030px] mx-auto">
            By combining the{" "}
            <span className="font-bold">
              Inflectiv Training Data Marketplace
            </span>{" "}
            with your custom repository, you can create AI models that
            outperform traditional solutions thanks to{" "}
            <span className="font-bold">superior data quality</span> and{" "}
            <span className="font-bold">scalability</span>.
          </SectionDetail>
        </div>
      </div>
    </section>
  );
}

export default ProductGrowth;
